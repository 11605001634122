"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Watch = void 0;
class Watch {
  constructor() {
    this.timestamps = new Map();
  }
  start(label) {
    if (this.timestamps.has(label)) {
      throw new Error(`Watch already started for label: ${label}`);
    }
    this.timestamps.set(label, {
      started: Date.now()
    });
  }
  stop(label) {
    const timestamp = this.get(label);
    if (typeof timestamp.elapsed !== "undefined") {
      throw new Error(`Watch already stopped for label: ${label}`);
    }
    const elapsed = Date.now() - timestamp.started;
    this.timestamps.set(label, {
      started: timestamp.started,
      elapsed
    });
  }
  get(label) {
    const timestamp = this.timestamps.get(label);
    if (typeof timestamp === "undefined") {
      throw new Error(`No timestamp found for label: ${label}`);
    }
    return timestamp;
  }
  elapsed(label) {
    const timestamp = this.get(label);
    const elapsed = timestamp.elapsed || Date.now() - timestamp.started;
    return elapsed;
  }
}
exports.Watch = Watch;
exports.default = Watch;
