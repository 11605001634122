"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delay = void 0;
function delay(timeout) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, timeout);
  });
}
exports.delay = delay;
